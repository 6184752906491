import styled from 'styled-components';
import { ReactComponent as CheckIcon } from 'assets/check-circle.svg';
import BreathingDetectionThumbnail from 'assets/feature-breathing-detection.png';
import SleepTrackingThumbnail from 'assets/feature-sleep-tracking.png';
import EventAlertsThumbnail from 'assets/feature-event-alerts.png';

const CardRoot = styled.div`
  padding: 12px;
  border-radius: 16px;
  gap: 12px;
  display: flex;
  background: #f0f4f4;
  flex-direction: column;
  margin-bottom: 8px;
`;

const CardHead = styled.div`
  display: flex;
  gap: 12px;
`;

const CardBody = styled.div`
  display: flex;
  justify-content: center;
`;

const CardTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #000000;
  margin-bottom: 4px;
`;

const CardSubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #484c4f;
`;

const CardIcon = styled.div`
  display: flex;
  align-items: center;
`;

export default function FeatureList() {
  const featuresList = [
    {
      title: 'Breathing Detection:',
      subTitle: 'Stay connected with every breath your baby takes.',
      thumbnail: BreathingDetectionThumbnail
    },
    {
      title: 'Sleep Tracking:',
      subTitle: 'Cherish peace of mind with insightful sleep pattern analysis.',
      thumbnail: SleepTrackingThumbnail
    },
    {
      title: 'Event Alerts:',
      subTitle: `Never miss a moment of your baby's precious activities.`,
      thumbnail: EventAlertsThumbnail
    }
  ];

  return (
    <>
      {featuresList.map((item) => (
        <CardRoot key={`card-root-${item.title}`}>
          <CardHead>
            <CardIcon>
              <CheckIcon />
            </CardIcon>
            <div>
              <CardTitle>{item.title}</CardTitle>
              <CardSubTitle>{item.subTitle}</CardSubTitle>
            </div>
          </CardHead>
          <CardBody>
            <img src={item.thumbnail} style={{ width: '100%' }} alt="" />
          </CardBody>
        </CardRoot>
      ))}
    </>
  );
}
