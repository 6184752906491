import styled from 'styled-components';
import { Button, styled as styledMUI } from '@mui/material';
import { Flex } from 'styles';
import { useLocation, useNavigate } from 'react-router-dom';
import FeatureList from './FeatureList';

const Container = styledMUI('div')(({ theme }) => ({
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '320px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '6px'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '440px'
  }
}));

const OfferContainer = styled.div`
  display: flex;
  padding: 16px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #d7eea1;
  margin-bottom: 24px;
  cursor: pointer;
`;

const ExclusiveOffer = styled.div`
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const TryMSg = styled.div`
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const Title = styled.div`
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.22px;
  margin-bottom: 12px;
  margin-bottom: 24px;
`;

const SubTitle = styled.div`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 12px;
  margin-bottom: 24px;
`;

export default function WhySubscribe() {
  const navigate = useNavigate();
  const location = useLocation();

  const { isFreeTrial, isUpgrade, deviceId, pairingFlow } = location.state;

  console.log('location ', location);

  return (
    <Container>
      <Flex direction="column" align="start" style={{ width: '100%' }}>
        <Title>Why Subscribe?</Title>
        <OfferContainer>
          <ExclusiveOffer>Exclusive Offer</ExclusiveOffer>
          <TryMSg>Get a free trial Today!</TryMSg>
        </OfferContainer>
      </Flex>

      <Flex
        direction="column"
        align="start"
        style={{ width: '100%', marginBottom: '60px' }}
      >
        <SubTitle>Feature Highlights:</SubTitle>

        <FeatureList />
      </Flex>

      <Flex
        direction="column"
        align="flex-end"
        style={{
          width: '100%',
          position: 'fixed',
          left: 0,
          bottom: 0,
          padding: '12px 20px',
          background: '#fff'
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            outline: 'none',
            '&:focus': {
              outline: 'none'
            }
          }}
          style={{
            width: '60%',
            maxWidth: '300px',
            borderRadius: '44px'
          }}
          onClick={() => {
            if (pairingFlow && deviceId) {
              navigate('/buySubscription', {
                state: {
                  isFreeTrial,
                  isUpgrade,
                  deviceId,
                  pairingFlow
                }
              });
            } else {
              navigate('/planlist', {
                state: {
                  isFreeTrial,
                  isUpgrade,
                  deviceId,
                  pairingFlow
                }
              });
            }
          }}
        >
          See Plans & Pricing
        </Button>
      </Flex>
    </Container>
  );
}
