/* eslint-disable react/no-unused-prop-types */
import { useState } from 'react';
import { Button, Dialog, styled as styledMUI } from '@mui/material';

import axios from 'axios';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  IPlan,
  SubscriptionPlanContent
} from 'components/SubscriptionPlanContent/SubscriptionPlanContent';
import PaymentOptions from 'views/SubscriptionInfo/componenets/PaymentOptions';
import { useLocation, useNavigate } from 'react-router-dom';
import { IsMobile } from 'hooks/isMobile';
import { useAppSelector } from 'store/hooks';
import { SubscriptionType } from 'services/api/subscriptionApi';

const Gutter = styledMUI('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    maxWidth: '320px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '6px'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '440px'
  }
}));

type IProps = {
  selectedSubscription?: SubscriptionType | null;
  togglePrimaryModal?: () => void;
  isUpgrade?: boolean;
  isFreeTrial?: boolean;
  deviceId?: string;
  isOnHigherPlan?: boolean;
  pairingFlow?: boolean;
};

type IPurchhasePayload = {
  country: string;
  sku_id: string;
  trial: boolean;
  action?: string;
  previous_purchase_id?: string;
};

function BuySubscription(props: IProps) {
  const location = useLocation();
  const {
    selectedSubscription,
    togglePrimaryModal,
    isUpgrade,
    isFreeTrial,
    deviceId,
    pairingFlow,
    isOnHigherPlan
  } = Object.keys(props).length > 0 ? props : location.state;

  console.log('BuySubscription-- props -- ', props);
  console.log('BuySubscription-- location -- ', location);
  console.log(
    'BuySubscription-- selectedSubscription -- ',
    selectedSubscription
  );
  console.log('BuySubscription-- isUpgrade -- ', isUpgrade);
  console.log('BuySubscription-- isOnHigherPlan -- ', isOnHigherPlan);
  console.log('BuySubscription-- isFreeTrial -- ', isFreeTrial);
  console.log('BuySubscription-- deviceId -- ', deviceId);
  console.log('BuySubscription-- pairingFlow -- ', pairingFlow);
  // debugger;

  const [planSelected, setPlanSelected] = useState<IPlan>();
  const [purchaseId, setPurchaseId] = useState<string>('');
  const [open, setOpen] = useState(false);
  const isMobile = IsMobile();
  const subscriptionData = useAppSelector<SubscriptionType[]>(
    (state) => state.subscription.subscriptionData
  );
  const navigate = useNavigate();
  // const openModal = () => {
  //   setOpen(true);
  // };
  const onClose = () => {
    setOpen(false);
  };

  const toggleSecondaryModal = () => {
    if (togglePrimaryModal) {
      togglePrimaryModal();
    } else {
      navigate('/MySubscription');
    }
    onClose();
  };

  const createPurchaseId = (plan: IPlan) => {
    const payload: IPurchhasePayload = {
      country: 'US',
      sku_id: plan.id,
      trial: isFreeTrial
    };
    if (isUpgrade && subscriptionData.length > 0 && selectedSubscription) {
      payload['action'] = 'Upgrade';
      payload['previous_purchase_id'] = selectedSubscription.purchase_id;
      payload['trial'] = selectedSubscription.trial;
    }

    // debugger;
    axios
      .post('piggybank/purchases', payload)
      .then((res) => {
        setPurchaseId(res.data.purchase_id);
        if (isUpgrade) {
          navigate('/payment-selections', {
            state: {
              isUpgrade,
              isFreeTrial,
              deviceId,
              planSelected: plan,
              purchaseId: res.data.purchase_id,
              oldSubscription: selectedSubscription
            }
          });
        } else {
          navigate('/selectDevice', {
            state: {
              isUpgrade,
              isFreeTrial,
              deviceId,
              planSelected: plan,
              purchaseId: res.data.purchase_id,
              oldSubscription: selectedSubscription
            }
          });
        }
      })
      .catch((err) => console.log('purchases err', err));
  };

  console.log('planSelected', planSelected);

  const onPlanSelection = (el: IPlan) => {
    console.log('onPlanSelection', el);
    setPlanSelected(el);
    createPurchaseId(el);
  };

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            maxWidth: '100%',
            minWidth: '75%',
            padding: '20px'
          }
        }}
        onClose={onClose}
        open={selectedSubscription === undefined && open && !isMobile}
      >
        <Button
          sx={{
            position: 'absolute',
            right: '20px',
            top: '20px',
            padding: '0px',
            width: '20px',
            minWidth: '20px',
            height: '20px',
            border: 'none',
            outline: 'none'
          }}
          onClick={onClose}
        >
          <CloseOutlinedIcon />
        </Button>
        <PaymentOptions
          isUpgrade={isUpgrade}
          deviceId={deviceId}
          planSelected={planSelected}
          purchaseId={purchaseId}
          toggleModal={toggleSecondaryModal}
          isFreeTrial={isFreeTrial}
          oldSubscription={selectedSubscription}
        />
      </Dialog>

      {selectedSubscription !== undefined && open && !isMobile ? (
        <PaymentOptions
          isUpgrade={isUpgrade}
          deviceId={deviceId}
          planSelected={planSelected}
          purchaseId={purchaseId}
          toggleModal={toggleSecondaryModal}
          isFreeTrial={isFreeTrial}
          oldSubscription={selectedSubscription}
        />
      ) : (
        <Gutter>
          <SubscriptionPlanContent
            onPlanSelection={onPlanSelection}
            isFreeTrial={isFreeTrial}
            isUpgrade={isUpgrade}
            isOnHigherPlan={isOnHigherPlan}
            deviceId={deviceId}
            pairingFlow={pairingFlow}
            selectedSubscription={selectedSubscription}
          />
        </Gutter>
      )}
    </>
  );
}
export default BuySubscription;
