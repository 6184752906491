import styled from 'styled-components';
import GreenCheck from 'assets/check-circle-green.png';
import { Flex } from 'styles';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CameraSnapshot from 'assets/camera-snapshot-placeholder.png';
import { IsMobile } from 'hooks/isMobile';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: #000;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.22px;
  margin-bottom: 12px;
  margin-top: 10px;
`;

const OfferContainer = styled.div`
  display: flex;
  padding: 16px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #d7eea1;
  margin-bottom: 24px;
  cursor: pointer;
`;

const ExclusiveOffer = styled.div`
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const TryMSg = styled.div`
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const Info = styled.div`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-left: 10px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  padding: 20px 0;
`;

const Image = styled.img<{
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.borderRadius || 0}px;
`;

function Features() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isFreeTrial, deviceId } = location.state || {};

  return (
    <Container>
      <Container>
        <Image
          src={CameraSnapshot}
          borderRadius="15"
          width={IsMobile() ? `${window.innerWidth - 40}` : '335'}
        />
        <Title>Unlock premium feature for a more powerful camera!</Title>
        <OfferContainer>
          <ExclusiveOffer>Exclusive Offer</ExclusiveOffer>
          <TryMSg>Try 15 days Free-Trial</TryMSg>
        </OfferContainer>
        <Flex mbottom="10">
          <Image src={GreenCheck} width="24" height="24" />
          <Info>Save all your videos</Info>
        </Flex>
        <Flex mbottom="10">
          <Image src={GreenCheck} width="24" height="24" />
          <Info>Video recording history</Info>
        </Flex>
        <Flex mbottom="10">
          <Image src={GreenCheck} width="24" height="24" />
          <Info>Smart AI Detections</Info>
        </Flex>
      </Container>
      <BottomContainer>
        <Button
          size="large"
          variant="contained"
          color="primary"
          style={{
            width: '100%',
            textTransform: 'none'
          }}
          onClick={() =>
            navigate('/buySubscription', {
              replace: true,
              state: {
                isFreeTrial,
                isUpgrade: false,
                deviceId
              }
            })
          }
        >
          Continue With Free-Trial
        </Button>
        <Button
          size="large"
          variant="outlined"
          color="primary"
          style={{
            width: '100%',
            textTransform: 'none',
            border: 'none'
          }}
          onClick={() =>
            navigate('/subscription-functions', {
              state: {
                isFreeTrial,
                isUpgrade: false,
                deviceId
              }
            })
          }
        >
          Continue without the free trial.
        </Button>
      </BottomContainer>
    </Container>
  );
}
export default Features;
