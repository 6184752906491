/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { CURRENCY } from 'constants/constants';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { Flex, Image } from 'styles';
import { IsMobile } from 'hooks/isMobile';
import CameraIcon from 'assets/camera_icon.png';
import Cry from 'assets/crying-baby.svg';
import Adfree from 'assets/ad-free.svg';
import Breath from 'assets/breathing-monitoring.svg';
import house from 'assets/smart_home.png';
import SleepAlert from 'assets/sleep-alert.svg';
import SleepTrack from 'assets/sleep-tracking.svg';
import Sharing from 'assets/people.svg';
import Support from 'assets/24-support.svg';
import CloudStorage from 'assets/cloud-storage.svg';
import VideoCameraIcon from 'assets/video-camera.svg';

import DiscountArrow from 'assets/discount-arrow.png';
import {
  CheckFreeTrialSubscription
  // SubscriptionType
} from 'services/api/subscriptionApi';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { GetPlanDetails, Plans } from 'services/api/plansApi';
import { updateFreeTrialEligibility } from 'store/reducers/userReducers';

import Spinner from 'components/Spinner';
import IOSSwitch from 'components/IOSSwitch';

import { endpoints } from 'services/api/endpoints';
import { CircularProgress, Dialog } from '@mui/material';

export interface IPlan {
  id: string;
  name: string;
  description: string[];
  type: string;
  trial_period: number;
  subscription_cycle: number;
  data_retention_days: number;
  level: number;
  supported_camera_count: number;
  prices: IPrice[];
  supported_features: IFeature[];
}

export interface IFeature {
  id: string;
  description: string;
}

export interface IPrice {
  country: string;
  currency: string;
  current_price: number;
  stripe_price_id: string;
  original_price: number;
}

const Heading = styled.div`
  color: #494141;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-transform: capitalize;
`;

const SubHeading = styled.div`
  color: #8c8a94;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.16px;
`;

const PlanCard = styled.div<{ isPopular?: boolean }>`
  min-width: 236px;
  border-radius: 24px;
  border: 1.5px solid ${(props) => (props.isPopular ? '#5FA4FB' : '#e5ebec')};
  background: ${(props) => (props.isPopular ? '#CDE3FE' : '#e5ebec')};
  margin-right: 10px;
  padding: 16px;
  align-self: stretch;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PlanHeading = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 4px;
`;

const FinalPlanCost = styled.div`
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.32px;
`;

const OriginalCost = styled.div`
  color: #484c4f;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: line-through;
`;

const DiscountDiff = styled.div`
  color: #fe832d;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-left: 5px;
`;

const PlanDetailPoint = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 10px;
`;

const PlanType = styled.div<{ active?: boolean }>`
  color: #494141;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  opacity: ${(props) => (props.active ? 0.3 : 1)};
`;

const DiscountInfo = styled.div`
  color: #fe832d;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  position: relative;
  margin-top: 5px;
`;

const DialogHeading = styled.div`
  color: #000;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
`;

const DialogSubHeading = styled.div`
  color: #484c4f;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
`;

const FlexContainer = styled.div``;

type IProps = {
  onPlanSelection?: (plan: IPlan) => void;
  isFreeTrial?: boolean;
  isUpgrade?: boolean;
  deviceId?: string;
  pairingFlow?: boolean;
  isOnHigherPlan?: boolean;
  selectedSubscription?: any;
};

type IIconMap = {
  [key: string]: string;
};

export interface PlanList {
  id: string;
  name: string;
  description: string[];
  supported_features: SupportedFeature[];
  type: string;
  trial_period: number;
  subscription_cycle: number;
  data_retention_days: number;
  supported_camera_count: number;
  level: number;
  prices: Price[];
}

export interface SupportedFeature {
  id: string;
  description: string;
}

export interface Price {
  country: string;
  currency: string;
  original_price: number;
  current_price: number;
  stripe_price_id: string;
}

const obj: IIconMap = {
  basic_features: CameraIcon,
  user_sharing: Sharing,
  '30_days_event': CloudStorage,
  enhanced_support: Support,
  smart_home: house,
  cry_detection: Cry,
  sleep_tracking: SleepTrack,
  sleep_alerts: SleepAlert,
  breathing_monitoring: Breath,
  ad_free: Adfree,
  unlimited_camera_supported: VideoCameraIcon,
  '1_camera_supported': VideoCameraIcon
};

type IPurchasePayload = {
  country: string;
  sku_id: string;
  trial: boolean;
  action?: string;
  previous_purchase_id?: string;
};

export function SubscriptionPlanContent(props: IProps) {
  const {
    onPlanSelection,
    isFreeTrial,
    isUpgrade,
    deviceId,
    pairingFlow,
    isOnHigherPlan,
    selectedSubscription
  } = props;
  console.log(' SubscriptionPlanContent props ', props);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // debugger;
  const [allPlans, setAllPlans] = useState<Plans[]>([]);
  const [checked, setChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [showSkipDialog, toggleShowSkipDialog] = useState(false);
  const [canPurchaseFreeTrial, toggleCanPurchaseFreeTrial] = useState(false);

  // const isEligibleForTrial = useAppSelector(
  //   (state) => state.user.isEligibleForTrial
  // );

  const subscriptionData = useAppSelector<any[]>(
    (state) => state.subscription.subscriptionData
  );

  console.log('SubscriptionPlanContent -- subscriptionData ', subscriptionData);
  console.log('SubscriptionPlanContent -- isOnHigherPlan ', isOnHigherPlan);

  const currentSubscription = selectedSubscription
    ? selectedSubscription
    : subscriptionData[0];

  // debugger;
  console.log(
    'SubscriptionPlanContent -- currentSubscription ',
    currentSubscription
  );

  // eslint-disable-next-line no-unneeded-ternary
  // const canPurchaseFreeTrial = isFreeTrial ? isFreeTrial : isEligibleForTrial;
  // const canPurchaseFreeTrial = isEligibleForTrial;
  console.log('canPurchaseFreeTrial ', canPurchaseFreeTrial);

  // const devicePresentInSubscription =
  //   subscriptionData.length > 0 && subscriptionData[0].devices.length > 0;

  // console.log('devicePresentInSubscription ', devicePresentInSubscription);

  // const isOnHigherPlan=
  // currentSubscription &&
  // currentSubscription.subscribe_status === 'Active' &&
  // currentSubscription.subscription_cycle === 12

  const isActiveSubscription =
    currentSubscription &&
    currentSubscription.purchase_id &&
    currentSubscription.subscribe_status === 'Active';

  console.log(
    'SubscriptionPlanContent -- isActiveSubscription ',
    isActiveSubscription
  );

  const isActiveYearlySubscription =
    isActiveSubscription && currentSubscription.subscription_cycle === 12;

  console.log(
    'SubscriptionPlanContent -- isActiveYearlySubscription ',
    isActiveYearlySubscription
  );

  // const isEligibleForUpgrade =
  //   isActiveSubscription && subscriptionData[0].subscription_cycle !== 12;

  // console.log('isEligibleForUpgrade ', isEligibleForUpgrade);

  // eslint-disable-next-line no-unneeded-ternary
  const canPurchaseUpgrade = isUpgrade; // ? isUpgrade : isEligibleForUpgrade;
  console.log(
    'SubscriptionPlanContent -- canPurchaseUpgrade ',
    canPurchaseUpgrade
  );

  const isAnyActiveNonLinkedSubscription = subscriptionData.find(
    (item) =>
      item.purchase_id &&
      item.subscribe_status === 'Active' &&
      item.devices.length === 0
  );

  console.log(
    'isAnyActiveNonLinkedSubscription ',
    isAnyActiveNonLinkedSubscription
  );

  // debugger;

  useEffect(() => {
    setIsLoading(true);
    Promise.all([GetPlanDetails(), CheckFreeTrialSubscription()])
      .then((res) => {
        const [allPlan, freeTrialEligibility] = res;
        // debugger;
        setAllPlans(allPlan.data);
        toggleCanPurchaseFreeTrial(freeTrialEligibility.data.trial);

        dispatch(updateFreeTrialEligibility(freeTrialEligibility.data.trial));

        console.log('setting All plans');
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleChange = () => {
    setChecked(!checked);
  };

  const findCurrentPlanLevel = () => {
    let curretPlanLevel = 0;
    // checking for level wrt current subscription
    allPlans?.forEach((el: IPlan) => {
      if (el?.id === currentSubscription?.sku_id) {
        curretPlanLevel = Number(el.level);
      }
    });
    return curretPlanLevel;
  };

  if (isLoading) {
    return <Spinner />;
  }

  const disableSelection = false; //

  const curretPlanLevel = currentSubscription ? findCurrentPlanLevel() : 0;

  const filterByLevel = (el: IPlan) => el.level >= curretPlanLevel;

  console.log('curretPlanLevel -- ', curretPlanLevel);
  console.log('allPlans -- ', allPlans);

  const yearlyPlans = allPlans.filter(
    (el: IPlan) =>
      el.subscription_cycle === 12 &&
      (disableSelection ? true : filterByLevel(el))
  );
  const monthlyPlans = allPlans.filter(
    (el: IPlan) =>
      el.subscription_cycle === 1 &&
      (disableSelection ? true : filterByLevel(el))
  );

  const typeOfPlans = checked ? yearlyPlans : monthlyPlans;

  console.log('typeOfPlans -- ', typeOfPlans);
  console.log('yearlyPlans -- ', yearlyPlans);
  console.log('monthlyPlans -- ', monthlyPlans);

  const createPurchaseId = (plan: IPlan) => {
    const payload: IPurchasePayload = {
      country: 'US',
      sku_id: plan.id,
      trial: canPurchaseFreeTrial
    };
    // debugger;
    if (canPurchaseUpgrade && currentSubscription) {
      payload['action'] = 'Upgrade';
      payload['previous_purchase_id'] = currentSubscription.purchase_id;
    }
    // debugger;
    axios
      .post(endpoints.createPurchaseIdUrl, payload)
      .then((res) => {
        // debugger;
        if (isUpgrade) {
          navigate('/payment-selections', {
            state: {
              isUpgrade: canPurchaseUpgrade,
              isFreeTrial: canPurchaseFreeTrial,
              planSelected: plan,
              purchaseId: res.data.purchase_id,
              deviceId,
              dontAddDevice: deviceId ? false : true,
              oldSubscription: currentSubscription
            }
          });
        } else {
          navigate('/selectDevice', {
            state: {
              isUpgrade,
              isFreeTrial: canPurchaseFreeTrial,
              planSelected: plan,
              purchaseId: res.data.purchase_id,
              deviceId,
              dontAddDevice: deviceId ? false : true,
              oldSubscription: currentSubscription
            }
          });
        }
      })
      .catch((err) => console.log('purchases err', err));
  };

  const getSupportedCameraCount = (supportedFeatures: any) => {
    const cameraSupportedCount = supportedFeatures
      .find((item: any) => item.id.includes('camera_supported'))
      ?.id.split('_')[0];

    return cameraSupportedCount === 'unlimited' ? 99 : 1;
  };

  const addCameraToSubscription = async (subscriptionObj: any) => {
    // debugger;

    const supportedCameraCount = getSupportedCameraCount(
      subscriptionObj.supported_features
    );
    let payload = {};
    // debugger;

    if (supportedCameraCount > 1) {
      // debugger;
      const deviceArray = subscriptionObj.map((el: any) => el?.device_id);
      const device_ids = deviceArray;
      device_ids.push(deviceId);

      // debugger;
      payload = {
        device_ids
      };
    } else {
      payload = {
        device_ids: [deviceId]
      };
    }

    let errorMsg = '';
    // debugger;
    axios
      .post(
        `/api/v1/parents/subscriptions/${subscriptionObj.purchase_id}/devices`,
        payload
      )
      .then((res) => {
        console.log('addCameraToSubscription success', res);
        // dispatch(fetchAllSubscription());
        // debugger;
      })
      .catch((err) => {
        console.log('addCameraToSubscription err->>', err);
        // debugger;

        errorMsg =
          'Sorry! We cannot add the device to your selected to your subscription plan';
        // setErrorMessage(
        //   'Sorry! We cannot add the device to your selected to your subscription plan'
        // );
        console.log('addCameraToSubscription', err);
      })
      .finally(() => {
        setIsLoading(false);
        // setPurchaseStatus(true);
        // debugger;

        navigate('/payment-success', {
          state: {
            isFreeTrial,
            isUpgrade,
            errorMessage: errorMsg,
            showSetupComplete: Boolean(deviceId),
            deviceId,
            purchaseId: subscriptionObj.purchase_id
          }
        });
      });
  };

  const handlePlanSelection = (el: IPlan) => {
    console.log('handlePlanSelection', el);
    // debugger;

    if (!pairingFlow && isAnyActiveNonLinkedSubscription && deviceId) {
      // debugger;
      addCameraToSubscription(isAnyActiveNonLinkedSubscription);
    } else {
      // debugger;
      createPurchaseId(el);
    }
  };

  /**
   * ShowToogle will remove the toggle switch if number of plans after level filter for yeraly/monthly is zero
   */
  const showToggle = !(yearlyPlans.length === 0 || monthlyPlans.length === 0);
  return (
    <>
      <Flex mtop="30" mbottom="16" direction="column">
        {canPurchaseFreeTrial && !canPurchaseUpgrade && (
          <>
            <Heading>Choose Your Plan & Enjoy 15 days Free Trial</Heading>
            <SubHeading>
              You will be charged once the 15 days Trial is completed
            </SubHeading>
          </>
        )}
        {canPurchaseFreeTrial && canPurchaseUpgrade && (
          <>
            <Heading>Choose the free trial plan Upgrade:</Heading>
            <SubHeading>
              You will be charged once the Trial is completed
            </SubHeading>
          </>
        )}
        {!canPurchaseFreeTrial && canPurchaseUpgrade && (
          <Heading>Upgrade the plan that fits your needs</Heading>
        )}
        {!canPurchaseFreeTrial && !canPurchaseUpgrade && (
          <Heading>Choose Your plan</Heading>
        )}
      </Flex>
      {showToggle && (
        <Flex justify="center" mtop="30" mbottom="30" direction="column">
          <Flex>
            <PlanType active={checked}>Monthly</PlanType>
            <IOSSwitch
              sx={{ m: 1, color: '#5FA4FB' }}
              checked={checked}
              onChange={handleChange}
              disabled={canPurchaseUpgrade || isActiveYearlySubscription}
            />
            <PlanType active={!checked}>Yearly</PlanType>
          </Flex>
          <Flex style={{ position: 'relative' }}>
            <DiscountInfo>*save 20%</DiscountInfo>
            <Image
              width="18"
              height="17"
              src={DiscountArrow}
              style={{ position: 'absolute', left: 75, bottom: 8 }}
            />
          </Flex>
        </Flex>
      )}
      <Flex
        direction="column"
        justify={
          IsMobile() && showToggle && typeOfPlans.length > 1
            ? 'start'
            : 'center'
        }
      >
        {typeOfPlans.map((el: IPlan) => {
          const { name, id, prices, supported_features } = el;
          const { currency } = prices[0];

          const showDiscount =
            prices[0]['current_price'] < prices[0]['original_price'];
          return (
            <PlanCard key={id} isPopular={false}>
              <FlexContainer>
                <PlanHeading>{name}</PlanHeading>
                {showDiscount && (
                  <Flex align="center" justify="center" mtop="4">
                    <OriginalCost>
                      {CURRENCY[currency]}
                      {prices[0]['original_price'] / 100}
                    </OriginalCost>
                    <DiscountDiff>
                      save {CURRENCY[currency]}
                      {(prices[0]['original_price'] -
                        prices[0]['current_price']) /
                        100}
                    </DiscountDiff>
                  </Flex>
                )}

                <FinalPlanCost>
                  {CURRENCY[currency]}
                  {prices[0]['current_price'] / 100}
                </FinalPlanCost>

                <hr style={{ borderColor: '#FFF' }} />
                {supported_features.map((element: IFeature) => {
                  const icon = obj[element.id];
                  return (
                    <Flex align="center" key={element.id} mbottom="10">
                      <Image width="20" height="20" src={icon} />
                      <PlanDetailPoint>{element.description}</PlanDetailPoint>
                    </Flex>
                  );
                })}
              </FlexContainer>
            </PlanCard>
          );
        })}

        {canPurchaseFreeTrial && (
          <Flex
            mtop="20"
            mbottom="10"
            align="flex-start"
            style={{
              fontSize: 14,
              fontWeight: 300,
              lineHeight: '18px',
              color: '#484C4F',
              width: '100%'
            }}
          >
            Your free trial begins immediately.
          </Flex>
        )}
        {currentSubscription && (
          <Flex
            mtop="20"
            mbottom="10"
            align="flex-start"
            style={{
              fontSize: 14,
              fontWeight: 300,
              lineHeight: '18px',
              color: '#484C4F',
              width: '100%'
            }}
          >
            {`You are currently on ${currentSubscription?.sku_name} ${
              isActiveYearlySubscription ? 'Yearly' : 'Monthly'
            } subscription plan.`}
          </Flex>
        )}

        <Flex
          mtop="20"
          mbottom="20"
          align="flex-start"
          style={{
            fontSize: 14,
            fontWeight: 300,
            lineHeight: '18px',
            color: '#484C4F',
            width: '100%'
          }}
        >
          Enjoy peace of mind with our premium features for your newborn.
        </Flex>

        <Flex
          mtop="10"
          mbottom="30"
          style={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr'
          }}
        >
          {canPurchaseFreeTrial ? (
            <Button
              color="primary"
              sx={{
                justifyContent: 'flex-start',
                justifySelf: 'flex-start',
                outline: 'none',
                '&:focus': {
                  outline: 'none'
                }
              }}
              variant="text"
              size="small"
              onClick={() => {
                toggleShowSkipDialog(true);
              }}
            >
              Skip
            </Button>
          ) : (
            <div />
          )}
          <Button
            color="primary"
            sx={{
              borderRadius: '25px',
              borderWidth: '1.5px',
              minWidth: '90px',
              justifySelf: 'flex-end',
              outline: 'none',
              '&:focus': {
                outline: 'none'
              }
            }}
            variant="contained"
            onClick={() => {
              setIsSubmiting(true);
              if (onPlanSelection) {
                onPlanSelection(typeOfPlans[0]);
              } else {
                handlePlanSelection(typeOfPlans[0]);
              }
            }}
            disabled={isSubmiting}
          >
            {canPurchaseFreeTrial && !canPurchaseUpgrade && 'Start free trial'}
            {canPurchaseUpgrade && 'Upgrade'}
            {!canPurchaseFreeTrial && !canPurchaseUpgrade && 'Buy now'}
            {isSubmiting && (
              <CircularProgress
                style={{ height: '15px', width: '15px', marginLeft: '10px' }}
                color="inherit"
              />
            )}
          </Button>
        </Flex>

        {showSkipDialog && (
          <Dialog
            open={showSkipDialog}
            PaperProps={{
              style: {
                margin: '8px'
              }
            }}
            maxWidth="xs"
          >
            <Flex
              direction="column"
              gap="24px"
              style={{ padding: '24px 16px' }}
            >
              <Flex direction="column" align="start">
                <DialogHeading>Are you Sure?</DialogHeading>

                <DialogSubHeading>
                  {`Don't miss out on our special free trial offer. Start
                  monitoring and ensuring your baby's safety today!`}
                </DialogSubHeading>
              </Flex>

              <Flex justify="space-between" style={{ width: '100%' }}>
                <Button
                  color="primary"
                  sx={{
                    justifyContent: 'flex-start'
                  }}
                  variant="text"
                  size="small"
                  onClick={() => {
                    navigate('/skip-trial', {
                      state: {
                        isFreeTrial,
                        deviceId,
                        pairingFlow
                      }
                    });
                  }}
                >
                  Go without subscription
                </Button>
                <Button
                  color="primary"
                  sx={{
                    borderRadius: '25px',
                    borderWidth: '1.5px',
                    fontSize: '13px'
                  }}
                  variant="contained"
                  onClick={() => {
                    toggleShowSkipDialog(false);
                  }}
                >
                  Go back to Plans
                </Button>
              </Flex>
            </Flex>
          </Dialog>
        )}
      </Flex>
    </>
  );
}
