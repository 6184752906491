import envConfig from 'configs/envConfig';

const url: string = envConfig('domainUrl') || '';

export const PARTNER_NAME = {
  LUNA_DEV: 'https://luna-baby-dev.instavision.ai',
  LUNA_STAGE: 'https://luna-baby-staging.instavision.ai',
  LUNA_PROD: 'https://baby.lunahomeai.com',
  LOCAL: 'http://localhost:3000',
  DEFAULT: url
};

console.log('url set during build ', url);

export const PARTNER_SHORT_NAME = {
  [PARTNER_NAME.DEFAULT]: 'instaview',
  [PARTNER_NAME.LOCAL]: 'instaview',
  [PARTNER_NAME.LUNA_DEV]: 'luna',
  [PARTNER_NAME.LUNA_STAGE]: 'luna',
  [PARTNER_NAME.LUNA_PROD]: 'luna'
};
