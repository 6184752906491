import { createTheme } from '@mui/material/styles';

import React from 'react';

declare module '@mui/material/styles' {
  interface TypeGradient {
    [key: string]: string;
  }

  interface Palette {
    gradient: TypeGradient;
    text: TypeText;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    contrastText: string;
    disabled: string;
    shadedText: string;
    nonInstavision?: string;
  }

  interface TypeColor {
    Darkest?: string;
    Darker?: string;
    Dark?: string;
    Base?: string;
    Light?: string;
    Lighter?: string;
    Lightest?: string;
    White?: string;
  }

  interface PaletteOptions {
    gradient?: TypeGradient;
  }

  interface TypeBackground {
    opposite: string;
    card: string;
    overlayBanner: string;
    banner: string;
    secondary: string;
    overlayBanner2: string;
    yellow: string;
  }
  interface TypographyVariants {
    CTA1: React.CSSProperties;
    CTA2: React.CSSProperties;
    CTA3: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    Body1: React.CSSProperties;
    Body2: React.CSSProperties;
    Body3: React.CSSProperties;
    Body1Medium: React.CSSProperties;
    Body1SemiBold: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    CTA1?: React.CSSProperties;
    CTA2?: React.CSSProperties;
    CTA3?: React.CSSProperties;
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    Body1?: React.CSSProperties;
    Body2?: React.CSSProperties;
    Body3?: React.CSSProperties;
    Body1Medium?: React.CSSProperties;
    Body1SemiBold?: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    CTA1: true;
    CTA2: true;
    CTA3: true;
    body3: true;
    body4: true;
    Body1: true;
    Body2: true;
    Body3: true;
    Body1Medium: true;
    Body1SemiBold: true;
  }
}

function AppThemeProvider(oemName: string) {
  const commonTheme = {
    typography: {
      fontFamily: ['sans-serif'].join(','),
      fontSize: 16,
      fontWeightBold: 600,

      h1: {
        fontSize: '32px',
        fontWeight: 500,
        lineHeight: '38px'
      },
      h2: {
        fontSize: '22px',
        fontWeight: 600,
        lineHeight: '26px'
      },
      h3: {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '20.5px'
      },
      h4: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '18.45px'
      },
      h5: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px'
      },
      h6: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '16.4px'
      },

      CTA1: {
        fontSize: '8.97px',
        fontWeight: 500,
        lineHeight: '9.19px'
      },
      CTA2: {
        fontSize: '8.76px',
        fontWeight: 400,
        lineHeight: '11.26px'
      },
      Body1: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18px'
      },
      Body2: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '12.83px'
      },
      Body3: {
        fontSize: '10px',
        fontWeight: 600,
        lineHeight: '16px'
      },

      Body1Medium: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px'
      },
      Body1SemiBold: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '14.35px'
      }
    }
  };

  const instavisionCoreTheme = createTheme(
    {
      palette: {
        primary: {
          main: '#5FA4FB'
        },
        secondary: {
          main: '#D7EEA1'
        },

        background: {
          default: '#F1F1F1',
          opposite: '#333333',
          paper: '#F1F1F1',
          card: '#EDEDED',
          banner: '#6171ff',
          overlayBanner: '#6171ff',
          secondary: '#5fa4fb',
          overlayBanner2: '#0faf62',
          yellow: '#F3B71D'
        },
        text: {
          primary: '#333333',
          secondary: '#000000',
          contrastText: '#ffffff',
          disabled: '#C3C1BD',
          shadedText: '#000000'
        },
        gradient: {
          green:
            'linear-gradient(266.79deg, rgba(67, 137, 225, 0.8) 2.66%, rgba(3, 203, 106, 0.8) 85.83%)'
        },
        action: {
          hover: '#F0F4F4'
        }
      }
    },
    commonTheme
  );

  const lunaTheme = createTheme(
    {
      palette: {
        primary: {
          main: '#1A949C'
        },
        secondary: {
          main: '#FF6F61'
        },

        background: {
          default: '#F1F1F1',
          opposite: '#333333',
          paper: '#F1F1F1',
          card: '#EDEDED',
          banner: '#4389E1',
          overlayBanner: '#4389e1',
          secondary: '#5fa4fb',
          overlayBanner2: '#1abc9c',
          yellow: '#F3B71D'
        },
        text: {
          primary: '#2C3E50',
          secondary: '#000000',
          contrastText: '#ffffff',
          disabled: '#C3C1BD',
          shadedText: '#000000',
          nonInstavision: '#FF6F61'
        },
        gradient: {
          green:
            'linear-gradient(266.79deg, rgba(67, 137, 225, 0.8) 2.66%, rgba(3, 203, 106, 0.8) 85.83%)'
        },
        action: {
          hover: '#F0F4F4'
        }
      }
    },
    commonTheme
  );

  const theme = instavisionCoreTheme;
  const luna = lunaTheme;
  if (oemName === 'luna') {
    return luna;
  }

  return theme;
}

export default AppThemeProvider;
